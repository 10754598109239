import { Key, MouseEvent, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { IAccountRole, ISelectOption } from 'interfaces';
import { authSelector, clansSelector } from 'store';
import {
  isRoot,
  ROLES_SELECT_OPTIONS,
  rolesTranslateMap,
  rolesValuesMap,
  TPlayerTable,
} from 'types/player';

import STable, { STableFetchParams } from 'components/ui/Table/STable';
import { STableCell } from 'components/ui/Table/STableCell';
import { IFilterValue } from 'components/ui/Table/STableHeader';
import { changeClanValue, convertFilterValueFromSelectOption } from 'utils';

import { getAccounts } from '../../api/account';
import { TSetBulkClanMembersInput } from '../../api/types/clan';
import { BulkClanMembersModal } from '../../components/BulkClanMembersModal';
import useEvent from '../../hooks/useEvent';
import {
  getClansThunk,
  setBulkClanMembersThunk,
} from '../../store/slices/clan/actions';
import { getAccountsThunk } from '../../store/slices/player/actions';
import eventDispatcher from '../../utils/events/eventDispatcher';
import { Event, EventTypes } from '../../utils/events/events';

import { TwoFactorSwitch } from './TwoFactorSwitch';

export const STATUS_SELECT_OPTIONS: ISelectOption[] = [
  { value: false, label: 'Активен' },
  { value: true, label: 'Забанен' },
];

export const MFA_SELECT_OPTIONS: ISelectOption[] = [
  { value: false, label: 'Отключена' },
  { value: true, label: 'Активна' },
];

const getHeadCellsData = (clans: ISelectOption[]) => [
  {
    id: 'player',
    numeric: false,
    disablePadding: false,
    label: 'Отображаемое имя',
    sortableProperty: 'name',
  },
  {
    id: 'external_id',
    numeric: false,
    disablePadding: false,
    label: 'Логин',
    sortableProperty: 'external_id',
  },
  {
    id: 'role',
    numeric: false,
    disablePadding: false,
    label: 'Роль',
    possibleValues: ROLES_SELECT_OPTIONS.map((el) => el.label),
  },
  {
    id: 'banned',
    numeric: false,
    disablePadding: false,
    label: 'Статус',
    possibleValues: STATUS_SELECT_OPTIONS.map((el) => el.label),
  },
  {
    id: 'native_clan',
    numeric: false,
    disablePadding: false,
    label: 'Родной департамент',
    possibleValues: ['Не выбрано', ...clans.map((el) => el.label)],
  },
  {
    id: 'clan',
    numeric: false,
    disablePadding: false,
    label: 'Департаменты',
    possibleValues: ['Не выбрано', ...clans.map((el) => el.label)],
  },
  {
    id: 'sessions_quantity',
    numeric: true,
    disablePadding: false,
    label: 'Активных сессий',
    sortableProperty: 'sessions_quantity',
  },
  {
    id: 'mfa_required',
    numeric: false,
    disablePadding: false,
    label: 'Двухфакторная аутентификация',
    possibleValues: MFA_SELECT_OPTIONS.map((el) => el.label),
  },
];

const changeRoleValue = (values: IAccountRole[]) =>
  values.length
    ? values
        .reduce((acc: string[], value: IAccountRole) => {
          if (value.clan_id !== '00000000000000000000000000') {
            if (value.clan_name) {
              acc.push(
                `${value.clan_name} - ${rolesTranslateMap[value.role_name]}`
              );
            } else {
              acc.push(`${rolesTranslateMap[value.role_name]}`);
            }
          }

          return acc;
        }, [])
        .join(', ')
    : 'Не выбрана';

export const UsersTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { id: accountId } = useAppSelector(authSelector);

  const [selectedFilters, setSelectedFilters] = useState<IFilterValue[]>([]);
  const [possibleValues, setPossibleValues] = useState<ISelectOption[]>([]);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [isOpenBulkAccessModal, setIsOpenBulkAccessModal] = useState(false);
  const [searchParams] = useSearchParams();

  const clans = useAppSelector(clansSelector);

  useEffect(() => {
    dispatch(getClansThunk());
  }, []);

  const search = searchParams.get('search');

  const setClanMembersBulkButtonClickedEvent: Event | undefined = useEvent(
    EventTypes.SET_CLAN_MEMBERS_BULK_BUTTON_CLICKED
  );

  useEffect(() => {
    if (setClanMembersBulkButtonClickedEvent !== undefined) {
      setIsOpenBulkAccessModal(true);
    }
  }, [setClanMembersBulkButtonClickedEvent]);

  const fetchRows = useMemo(
    () => async (params: STableFetchParams) => {
      const newFilterValues: IFilterValue[] = selectedFilters.map(
        (selectedFilter) => {
          let convertedValues: string[];

          switch (selectedFilter.id) {
            case 'banned':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                STATUS_SELECT_OPTIONS
              );

              break;
            case 'role':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                ROLES_SELECT_OPTIONS
              );

              break;
            case 'clan':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                possibleValues
              );

              break;
            case 'native_clan':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                [{ label: 'Не выбрано', value: null }, ...possibleValues]
              );

              break;
            case 'mfa_required':
              convertedValues = convertFilterValueFromSelectOption(
                selectedFilter.values,
                MFA_SELECT_OPTIONS
              );

              break;
            default:
              convertedValues = selectedFilter.values;
          }

          return {
            id: selectedFilter.id,
            values: convertedValues,
          };
        }
      );

      const response = await getAccounts({
        search: search,
        offset: params.start,
        limit: params.size,
        filters: newFilterValues,
      }).then((r) => r.data);

      const clans =
        response?.possible_values.find((el) => el.id === 'clan')?.values || [];

      setPossibleValues(
        clans.map((el) => ({
          label: el.name,
          value: el.id,
        }))
      );

      return {
        rows: response?.accounts || [],
        total: response?.total || 0,
      };
    },
    [clans, search, selectedFilters]
  );

  const ENTITIES_HEAD_CELLS = useMemo(
    () => getHeadCellsData(possibleValues),
    [possibleValues]
  );

  const handleRowClick = (_row: TPlayerTable, rowId: Key) => {
    navigate(`/admin-panel/users/${rowId}`, {});
  };

  const handleCloseModal = () => {
    setIsOpenBulkAccessModal(false);
  };

  const handleSetBulkClanMembers = async (
    params: Omit<TSetBulkClanMembersInput, 'account_ids'>
  ) => {
    const paramsWithAccounts: TSetBulkClanMembersInput = {
      ...params,
      account_ids: selectedRows,
    };

    await dispatch(setBulkClanMembersThunk(paramsWithAccounts));
    setIsOpenBulkAccessModal(false);

    eventDispatcher.dispatch({
      name: EventTypes.RESET_TABLE_SELECTION,
    });

    dispatch(getAccountsThunk());
    dispatch(getClansThunk());
  };

  const handleCellClick = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
  };

  return (
    <div className="relative">
      <STable<TPlayerTable, string>
        headCells={ENTITIES_HEAD_CELLS}
        handleSelectedRows={(rows) =>
          setSelectedRows(rows.map((row) => row.id))
        }
        rowIdExtractor={(row) => row?.id}
        fetchRows={fetchRows}
        handleRowClick={handleRowClick}
        onFiltersChange={setSelectedFilters}
        selectedFilters={selectedFilters}
        isPagination
        renderRowCells={(row, props) => {
          const roleKey = row.roles
            .map(({ role_name }) => role_name)
            ?.join(',');

          const clans = changeClanValue(row.roles);

          return (
            <>
              <STableCell
                {...props}
                key={`name-${row.name}`}
                propName="name"
                cellType="text"
              >
                {row.name}
              </STableCell>
              <STableCell
                {...props}
                key={`external_id-${row.name}`}
                propName="external_id"
                cellType="text"
              >
                {row.external_id}
              </STableCell>
              <STableCell
                {...props}
                key={roleKey}
                propName="role"
                cellType="text"
              >
                {changeRoleValue(row.roles)}
              </STableCell>
              <STableCell
                {...props}
                key={`${row.status}-${row.name}`}
                propName="status"
                cellType="text"
              >
                {row.banned ? 'Забанен' : 'Активный'}
              </STableCell>
              <STableCell
                {...props}
                key={`clans-${row.clan_name}-${row.name}`}
                propName="clans"
                cellType="text"
              >
                {row.clan_name || 'Не выбрано'}
              </STableCell>
              <STableCell
                {...props}
                key={`${clans.length ? clans : 'Нет'}-${row.name}`}
                propName="clans"
                cellType="text"
              >
                {clans || 'Не выбрано'}
              </STableCell>
              <STableCell
                {...props}
                key={`sessions-${row.clan_name}-${row.name}`}
                propName="sessions_quantity"
                cellType="text"
              >
                {row.sessions_quantity || '0'}
              </STableCell>
              <STableCell
                {...props}
                key={`mfaRequired-${row.name}-${!!row.mfa_required}`}
                propName="mfa_required"
                cellType="checkbox"
                isEditMode={true}
              >
                <div onClick={handleCellClick}>
                  <TwoFactorSwitch
                    id={row.id}
                    mfaRequired={!!row.mfa_required}
                    disabled={
                      row.id === rolesValuesMap.root && !isRoot(accountId)
                    }
                  />
                </div>
              </STableCell>
            </>
          );
        }}
      />
      {isOpenBulkAccessModal && (
        <BulkClanMembersModal
          onClose={handleCloseModal}
          onSubmit={handleSetBulkClanMembers}
          clanOptions={clans.clans.map(({ id, name }) => ({
            value: String(id),
            label: name,
          }))}
        />
      )}
    </div>
  );
};
