import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatTimestamp } from 'api/utils/timeUtils';
import cn from 'classnames';
import { HEADER_HEIGHT } from 'constants/common';
import {
  useAppDispatch,
  useAppSelector,
  useBreakPoint,
  useWindowSize,
} from 'hooks';
import { ReactComponent as CopyIcon } from 'images/newIcons/copy2.svg';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';
import { recordsSelector } from 'store/slices/records';
import {
  deleteRecordThunk,
  getRecordThunk,
} from 'store/slices/records/actions';

import { DeleteModal } from 'components/DeleteModal';
import { Player } from 'components/Player';
import { Loader } from 'components/ui/Loader';
import { copyToClipboard } from 'utils';

interface IRecordInfoProps {
  recordId: string;
}

const PADDING = 5;

export const RecordInfo: FC<IRecordInfoProps> = ({ recordId }) => {
  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const { selectedRecord, pending } = useAppSelector(recordsSelector);

  const dispatch = useAppDispatch();

  const { height } = useWindowSize();

  const breakPoint = useBreakPoint();

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getRecordThunk(recordId));
  }, [recordId]);

  const heightScreen = height - HEADER_HEIGHT - PADDING;

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  const handleRemoveRecord = async () => {
    await dispatch(deleteRecordThunk(recordId));

    navigate(-1);
  };

  if (pending)
    return (
      <Loader className="h-[calc(100%-24px)] w-[calc(100%-24px)] flex justify-center items-center" />
    );

  return (
    <>
      {selectedRecord && (
        <div
          className={cn('flex flex-row gap-[4px]', { 'flex-col': isMobile })}
          style={{ height: heightScreen }}
        >
          <Player src={selectedRecord.recordSrc} />
          <div
            className={cn('flex flex-col h-full', {
              'min-w-[410px]': !isMobile,
            })}
          >
            <div className="bg-light px-[16px] py-[9px] rounded-t-[10px]">
              <span className="tpg-c2 text-tpg_base">справка записи</span>
            </div>
            <div className="flex flex-col gap-[8px] bg-dark h-full p-[16px]">
              <span className="tpg-b2">Основная информация</span>
              <div className="border border-solid rounded-[10px] border-dark_product">
                <div className="flex flex-col px-[16px] py-[12px]">
                  <span className="tpg-c2 text-tpg_light">Дата записи</span>
                  <span className="tpg-c1 text-tpg_base">
                    {formatTimestamp(selectedRecord.startedAt)}
                  </span>
                </div>
                <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                  <span className="tpg-c2 text-tpg_light">Источник</span>
                  <span className="tpg-c1 text-tpg_base">
                    {selectedRecord.publisherName}
                  </span>
                </div>
                <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                  <span className="tpg-c2 text-tpg_light">Департамент</span>
                  <span className="tpg-c1 text-tpg_base">
                    {selectedRecord.clanName}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-row gap-[12px] items-center justify-end bg-light px-[16px] py-[9px] rounded-b-[10px]">
              <div
                className="group flex flex-row items-center gap-[4px] cursor-pointer hover:text-bright_product transition"
                onClick={() =>
                  copyToClipboard(
                    `${window.location.origin}/records/${selectedRecord.id}`,
                    'Ссылка скопирована'
                  )
                }
              >
                <CopyIcon className="group-hover:[&>path]:fill-bright_product [&>path]:transition" />
                <span className="tpg-c2">поделиться</span>
              </div>
              <div
                className="group flex flex-row items-center gap-[4px] cursor-pointer hover:text-bright_product transition"
                onClick={() => setOpenDeleteModal(true)}
              >
                <TrashIcon className="group-hover:[&>path]:fill-bright_product [&>path]:transition" />
                <span className="tpg-c2">удалить</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpenDeleteModal && (
        <DeleteModal
          onClose={() => setOpenDeleteModal(false)}
          onRemove={handleRemoveRecord}
          title="Вы уверены, что хотите удалить запись?"
        />
      )}
    </>
  );
};
