import { FC, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { NOTHING_WAS_FOUND_MESSAGE } from 'constants/routes';
import { useAppDispatch, useBreakPoint } from 'hooks';
import { ReactComponent as PlusIcon } from 'images/newIcons/plus.svg';
import { IClan } from 'interfaces/clan';
import { IPlayer, IUpdatedPlayerData } from 'interfaces/player';
import {
  deleteClanMemberThunk,
  getClanMembersThunk,
} from 'store/slices/clan/actions';
import { registerWithClanThunk } from 'store/slices/player/actions';

import { TextInput } from 'components/ui';
import { getWordDayForm } from 'utils';

import { AddPlayerCard } from './components/AddPlayerCard';
import { InfoTable } from './components/InfoTable';

interface PlayerInfoProps {
  role: EUserRole;
  selectedClan: IClan;
  playersSelectedClan: IPlayer[];
  newPlayers: IPlayer[];
  onAddPlayer: (value: IPlayer) => void;
  onRemovePlayer: (id: string) => void;
  onUpdatePlayer: (value: IUpdatedPlayerData) => void;
  isNewClan?: boolean;
  newClanName?: string;
  isAccessList?: boolean;
}

export const PlayerInfo: FC<PlayerInfoProps> = ({
  role,
  selectedClan,
  playersSelectedClan,
  newPlayers,
  onAddPlayer,
  onRemovePlayer,
  onUpdatePlayer,
  isNewClan,
  newClanName,
  isAccessList = false,
}) => {
  const breakPoint = useBreakPoint();
  const dispatch = useAppDispatch();
  const containerRef = useRef<HTMLDivElement>(null);

  const [searchValue, setSearchValue] = useState('');
  const [filteredPlayers, setFilteredPlayers] = useState<IPlayer[]>([]);
  const [isNewPlayer, setNewPlayer] = useState(false);

  const players = isNewClan ? newPlayers : playersSelectedClan;

  useEffect(() => {
    if (!isNewClan) {
      dispatch(getClanMembersThunk(selectedClan.id));
    }
  }, [dispatch, selectedClan.id, isNewClan]);

  useEffect(() => {
    setFilteredPlayers(players);
  }, [players]);

  const handleAddPlayer = () => {
    setNewPlayer(true);
    scrollToTop();
  };

  const handleAddNewPlayer = (player: IPlayer) => {
    if (!isNewClan && selectedClan) {
      dispatch(
        registerWithClanThunk({
          email: player.email,
          name: player.name,
          password: player.password,
          password2: player.password,
          clanId: selectedClan.id,
          roleId: player.role,
          streamerId: player.streamer_id,
          isAddedFromClan: true,
        })
      );
    }
    onAddPlayer(player);
  };

  const handleRemovePlayer = (id: string) => {
    if (!isNewClan) {
      dispatch(
        deleteClanMemberThunk({ clanId: selectedClan.id, memberId: id })
      );
    }
    onRemovePlayer(id);
  };

  const handleClose = () => {
    setNewPlayer(false);
  };

  const handleChangeSearch = (value: string) => {
    setSearchValue(value);
    setFilteredPlayers(filterPlayers(value));
  };

  const filterPlayers = (searchValue: string) =>
    searchValue
      ? players.filter(({ name }) =>
          name.toLowerCase().includes(searchValue.toLowerCase())
        )
      : players;

  const scrollToTop = () => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  const wordForm = getWordDayForm(players.length, [
    'пользователь',
    'пользователя',
    'пользователей',
  ]);

  return (
    <div
      ref={containerRef}
      className={cn('w-6/12 min-h-full overflow-auto', {
        'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
    >
      <div className="flex flex-row justify-between bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[2]">
        <span>{`${players.length} ${wordForm}`}</span>
        <div
          className="group cursor-pointer flex items-center justify-center transition"
          onClick={handleAddPlayer}
        >
          {(role === EUserRole.ADMIN || role === EUserRole.ROOT) && (
            <PlusIcon className="scale-125 group-hover:[&>g>path]:fill-bright_product [&>g>path]:transition" />
          )}
        </div>
      </div>
      {players.length > 10 && (
        <TextInput
          onChange={handleChangeSearch}
          value={searchValue}
          placeholder="Начните поиск по пользователям"
          searchIcon
          isReset
          inputClassName="!bg-ultrablack !h-[32px] !pl-[32px]"
          className="!border !border-solid !border-tpg_light rounded-[5px] [&>div>svg]:!ml-[12px] [&>div>svg]:!w-4 [&>div>svg]:!h-4 mx-[16px] mb-[8px]"
        />
      )}
      <div className="flex flex-col gap-[16px] px-[16px] pt-[8px] pb-[16px]">
        {isNewPlayer && (
          <AddPlayerCard
            members={selectedClan.players}
            role={role}
            setNewPlayer={handleAddNewPlayer}
            onClose={handleClose}
          />
        )}
        {filteredPlayers.length
          ? filteredPlayers.map((info) => (
              <InfoTable
                key={info.id}
                globalRole={role}
                onRemove={handleRemovePlayer}
                onUpdatePlayer={onUpdatePlayer}
                isNewClan={isNewClan}
                newClanName={newClanName}
                clanId={selectedClan.id}
                members={selectedClan.players}
                launchedStreams={2} // temporary mock
                editable={!isAccessList}
                {...info}
              />
            ))
          : searchValue && (
              <span className="text-tpg_base tpg-c2">
                {NOTHING_WAS_FOUND_MESSAGE}
              </span>
            )}
      </div>
    </div>
  );
};
