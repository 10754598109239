import { FC, useMemo, useState } from 'react';
import { EUserRole } from 'constants/profile';
import { useBreakPoint } from 'hooks';
import { IClan } from 'interfaces/clan';

import { Button, Select, TextInput } from 'components/ui';
import { compareObjects } from 'utils';

import { IOption } from '../../interfaces';

interface BasicInfoProps {
  role: EUserRole;
  clanInfo: IClan;
  initialClanInfo: IClan;
  onChange: (value: IClan) => void;
  onSave: () => void;
  isNewClan?: boolean;
}

const mockTableHead = [
  {
    id: 'clan',
    label: 'Департамент',
  },
  {
    id: 'workArea',
    label: 'Район работы',
  },
  {
    id: '2faDisable',
    label: 'Запрет просмотра эфиров без 2FA',
  },
];

const VIEW_RESTRICT_OPTIONS: IOption[] = [
  { label: 'Нет запрета', value: 'false' },
  { label: 'Запрещено', value: 'true' },
];

export const BasicInfo: FC<BasicInfoProps> = ({
  role,
  clanInfo,
  initialClanInfo,
  onChange,
  onSave,
  isNewClan,
}) => {
  const breakPoint = useBreakPoint();

  const restrictInitValue =
    VIEW_RESTRICT_OPTIONS.find(
      (option) => option.value === String(clanInfo.require_2fa_for_view)
    ) || VIEW_RESTRICT_OPTIONS[0];

  const [restrict, setRestrict] = useState<IOption>(restrictInitValue);

  const isShowButtons = useMemo(
    () => !isNewClan && !compareObjects(initialClanInfo, clanInfo),
    [isNewClan, initialClanInfo, clanInfo]
  );

  const handleChangeClan = (value: string) => {
    onChange({ ...clanInfo, name: value });
  };

  const handleChangeWorkArea = (value: string) => {
    onChange({ ...clanInfo, work_area: value });
  };

  const handleSelectRestrictOption = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      onChange({ ...clanInfo, require_2fa_for_view: value.value === 'true' });
      setRestrict(value);
    }
  };

  const handleChangeLongitude = (value: string) => {
    onChange({ ...clanInfo, longitude: Number(value) });
  };

  const handleChangeLatitude = (value: string) => {
    onChange({ ...clanInfo, latitude: Number(value) });
  };

  const handleChangeCoordinates = (value: string) => {
    const coordinates: number[][][] = [
      value
        .split(',')
        .map(Number)
        .reduce((acc: number[][], value, index) => {
          if (index % 2 === 0) {
            acc.push([value]);
          } else {
            acc[acc.length - 1].push(value);
          }

          return acc;
        }, []),
    ];

    onChange({ ...clanInfo, coordinates });
  };

  const handleClickCancel = () => {
    onChange(initialClanInfo);
  };

  return !(breakPoint === 'mobile' || breakPoint === 'tablet') ? (
    <div className="w-6/12 border-r border-solid border-r-tpg_light overflow-auto min-h-full">
      <div className="bg-ultrablack px-[16px] pt-[16px] pb-[8px] sticky top-0 z-[3]">
        <span>Основная информация</span>
      </div>
      <div className="px-[16px] pt-[8px] pb-[16px] [&>div]last:border-b-none">
        <div className="flex flex-row border border-solid border-dark_product rounded-[10px]">
          <div className="flex flex-col w-6/12 border-r border-solid border-r-dark_product min-w-[195px]">
            {mockTableHead.map(({ id, label }) => (
              <div
                key={id}
                style={{
                  minHeight: '44px',
                }}
                className="p-[12px] border-b border-solid border-b-dark_product max-h-[44px] last:!border-none"
              >
                <span className="tpg-c1">{label}</span>
              </div>
            ))}
          </div>
          <div className="flex flex-col w-6/12">
            <div className="p-[12px] border-b border-solid border-b-dark_product h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.name}</span>
              ) : (
                <TextInput
                  onChange={handleChangeClan}
                  placeholder="Введите значение"
                  value={clanInfo.name}
                  inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            <div className="p-[12px] h-[44px] text-tpg_base border-b border-solid border-b-dark_product tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.work_area || 'Не выбрано'}</span>
              ) : (
                <TextInput
                  onChange={handleChangeWorkArea}
                  placeholder="Введите значение"
                  value={clanInfo.work_area || ''}
                  inputClassName="bg-ultrablack h-[19px] !pl-0 !text-tpg_base"
                />
              )}
            </div>
            <div className="p-[12px] h-[44px] text-tpg_base tpg-c1">
              {role === EUserRole.MODERATOR ? (
                <span>{clanInfo.name}</span>
              ) : (
                <Select
                  value={restrict}
                  options={VIEW_RESTRICT_OPTIONS}
                  onSelect={handleSelectRestrictOption}
                  emptyOptionText="Не выбрано"
                  className="!pr-0 !min-h-[19px] w-full"
                  isOptionEmpty={false}
                />
                // <TextInput
                //   onChange={handleChangeClan}
                //   placeholder="Введите значение"
                //   value={clanInfo.name}
                //   inputClassName="bg-ultrablack !h-[19px] !pl-0 !text-tpg_base"
                // />
              )}
            </div>
          </div>
        </div>
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px] px-[16px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button onClick={onSave} title="Сохранить" className="w-full" />
        </div>
      )}
    </div>
  ) : (
    <div className="flex flex-col px-[16px] gap-[16px] pt-[16px]">
      <span>Основная информация</span>
      <div className="border border-solid rounded-[10px] border-dark_product">
        <div className="h-[61px] flex flex-col px-[16px] py-[10px]">
          <span className="tpg-c2 text-tpg_light">департамент</span>
          {role === EUserRole.MODERATOR ? (
            <span>{clanInfo.name}</span>
          ) : (
            <TextInput
              onChange={handleChangeClan}
              placeholder="Введите название"
              value={clanInfo.name}
              inputClassName="bg-ultrablack !h-[19px] !pl-0"
            />
          )}
        </div>
        <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Район работы</span>
          {role === EUserRole.MODERATOR ? (
            <span>{clanInfo.work_area || 'Не выбрано'}</span>
          ) : (
            <TextInput
              onChange={handleChangeWorkArea}
              placeholder="Введите значение"
              value={clanInfo.work_area}
              inputClassName="bg-ultrablack h-[19px] !pl-0"
            />
          )}
        </div>
        {/* <CollapseContainer
          hiddenBlock={
            <>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Долгота</span>
                {role === EUserRole.MODERATOR ? (
                  <span>{clanInfo.longitude}</span>
                ) : (
                  <TextInput
                    onChange={handleChangeLongitude}
                    placeholder="Введите значение"
                    value={clanInfo.longitude}
                    inputClassName="bg-ultrablack !h-[19px] !pl-0"
                  />
                )}
              </div>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">Широта</span>
                {role === EUserRole.MODERATOR ? (
                  <span>{clanInfo.latitude}</span>
                ) : (
                  <TextInput
                    onChange={handleChangeLatitude}
                    placeholder="Введите значение"
                    value={clanInfo.latitude}
                    inputClassName="bg-ultrablack !h-[19px] !pl-0"
                  />
                )}
              </div>
              <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
                <span className="tpg-c2 text-tpg_light">
                  Координаты полигона
                </span>
                <div
                  className={cn('tpg-c1', {
                    'overflow-auto': role === EUserRole.MODERATOR,
                  })}
                >
                  {role === EUserRole.MODERATOR ? (
                    <span className="pr-[12px]">{clanInfo.coordinates}</span>
                  ) : (
                    <TextInput
                      onChange={handleChangeCoordinates}
                      placeholder="Введите значение"
                      value={
                        clanInfo.coordinates.length
                          ? clanInfo.coordinates.flat().join(',')
                          : ''
                      }
                      inputClassName="bg-ultrablack !h-[19px] !pl-0"
                    />
                  )}
                </div>
              </div>
            </>
          }
        >
        </CollapseContainer> */}
      </div>
      {isShowButtons && (
        <div className="flex flex-row gap-[12px]">
          <Button
            onClick={handleClickCancel}
            title="Отмена"
            className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
          />
          <Button onClick={onSave} title="Сохранить" className="w-full" />
        </div>
      )}
    </div>
  );
};
