import { useAppSelector } from 'hooks';
import { recordsSelector } from 'store/slices/records';

import { BackButton } from 'components/BackButton';

export const BackToRecordsButton = () => {
  const { selectedRecord } = useAppSelector(recordsSelector);

  return (
    <BackButton
      title="К записям департамента"
      path={
        selectedRecord?.clanId
          ? `/records/department/${selectedRecord?.clanId}`
          : '/records'
      }
    />
  );
};
