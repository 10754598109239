import { FC, useId, useState } from 'react';
import cn from 'classnames';
import { EUserRole } from 'constants/profile';
import { IOption } from 'interfaces';
import { IPlayer } from 'interfaces/player';
import { ROLE_OPTIONS } from 'mock';
import { rolesTranslateMap, rolesValuesMap } from 'types/player';

import { CopyIcon } from 'components/CopyIcon';
import { GeneratePassword } from 'components/GeneratePassword';
import { Button, Select, TextInput } from 'components/ui';
import { getUserFormFieldsError } from 'utils';

interface AddPlayerCardProps {
  members: IPlayer[];
  role: string;
  onClose: () => void;
  setNewPlayer: (data: Omit<IPlayer, 'launchedStreams'>) => void;
}

export const AddPlayerCard: FC<AddPlayerCardProps> = ({
  members = [],
  role,
  onClose,
  setNewPlayer,
}) => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [selectedRole, setSelectedRole] = useState<IOption>(ROLE_OPTIONS[1]);

  const [selectedStreamer, setSelectedStreamer] = useState<IOption>();

  const [basicInfoFieldsErrorMessage, setBasicInfoFieldsErrorMessage] =
    useState({
      name: '',
      email: '',
      password: '',
    });

  const id = useId();

  const handleChangeName = (value: string) => {
    setName(value);
  };

  const handleChangeLogin = (value: string) => {
    setEmail(value);
  };

  const handleChangePassword = (value: string) => {
    setPassword(value);
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);
    }
  };

  const handleSelectStreamer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedStreamer(value);
    }
  };

  const handleClose = () => {
    onClose();
    setName('');
    setEmail('');
    setPassword('');

    setSelectedRole({
      value: '',
      label: '',
    });
  };

  const handleSave = () => {
    const error = getUserFormFieldsError({
      password,
      name,
      email,
    });

    if (error.email.length || error.name.length || error.password.length) {
      setBasicInfoFieldsErrorMessage(error);

      return;
    }

    setNewPlayer({
      id,
      banned: false,
      name,
      role: selectedRole.value as EUserRole,
      streamer_id: selectedStreamer?.value,
      clans: [],
      status: '',
      access: [],
      external_id: email,
      email,
      password,
      roles: [],
      clan_id: null,
      clan_name: '',
    });

    handleClose();
  };

  const handleGeneratePassword = (value: string) => {
    setPassword(value);
  };

  const isDisabled =
    !name ||
    !email ||
    !password ||
    !selectedRole.label ||
    selectedRole.label === 'Не выбрана' ||
    (selectedRole.label === EUserRole.NAVIGATOR && !selectedStreamer);

  const filteredRoleOptions =
    role === EUserRole.ADMIN
      ? ROLE_OPTIONS.filter(
          (option) => option.label !== rolesTranslateMap[role]
        )
      : ROLE_OPTIONS;

  const streamerOptions = members
    .filter((member) =>
      member.roles.some((role) => role.role_name === EUserRole.USER)
    )
    .map((member) => ({
      value: member.id,
      label: member.name,
    }));

  return (
    <div className="flex flex-col gap-[16px]">
      <div className="border border-solid rounded-[10px] border-dark_product">
        <div className="flex flex-col px-[16px] py-[10px] border-b border-solid border-b-dark_product">
          <span className="tpg-c2 text-tpg_light">Отображаемое имя</span>
          <TextInput
            onChange={handleChangeName}
            value={name}
            placeholder="Введите отображаемое имя кириллицей"
            inputClassName="bg-ultrablack h-[20px] !pl-0"
            error={basicInfoFieldsErrorMessage.name}
          />
        </div>
        <Select
          value={selectedRole}
          options={filteredRoleOptions}
          onSelect={handleSelectRole}
          placeholder="Роль"
          isOptionEmpty={false}
          className={cn('pl-[16px] rounded-t-[10px]', {
            'rounded-b-[10px]':
              selectedRole.value !== rolesValuesMap[EUserRole.NAVIGATOR],
            'border-b border-solid border-b-dark_product':
              selectedRole.value === rolesValuesMap[EUserRole.NAVIGATOR],
          })}
        />
        {selectedRole.value === rolesValuesMap[EUserRole.NAVIGATOR] && (
          <Select
            value={selectedStreamer || []}
            options={streamerOptions}
            onSelect={handleSelectStreamer}
            placeholder="Источник"
            isOptionEmpty={false}
            className="pl-[16px] rounded-b-[10px] rounded-t-[10px]"
          />
        )}
        <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Логин</span>
          <div className="flex flex-row items-start gap-[8px]">
            <TextInput
              onChange={handleChangeLogin}
              value={email}
              placeholder="Введите логин латиницей"
              inputClassName="bg-ultrablack h-[20px] !pl-0"
              className="w-full"
              error={basicInfoFieldsErrorMessage.email}
            />
            <CopyIcon value={email} />
          </div>
        </div>
        <div className="flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
          <span className="tpg-c2 text-tpg_light">Пароль</span>
          <div className="flex flex-row justify-between items-start">
            <TextInput
              onChange={handleChangePassword}
              value={password}
              placeholder="Сгенерируйте пароль"
              inputClassName="bg-ultrablack h-[20px] !pl-0"
              className="w-full"
              error={basicInfoFieldsErrorMessage.password}
            />
            <div className="flex flex-row gap-[8px] whitespace-nowrap">
              <GeneratePassword onClick={handleGeneratePassword} />
              {password && <CopyIcon value={password} />}
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-[12px]">
        <Button
          onClick={handleClose}
          title="Отмена"
          className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
        />
        <Button
          onClick={handleSave}
          disabled={isDisabled}
          title="Добавить"
          className="w-full"
        />
      </div>
    </div>
  );
};
