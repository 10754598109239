import { FC, MouseEvent, useEffect, useMemo, useRef, useState } from 'react';
import { updateAccountRole, updateAccountStreamerId } from 'api/account';
import cn from 'classnames';
import { useAppDispatch, useClickOutside } from 'hooks';
import { IOption } from 'interfaces';
import { IClanPlayer, IPlayer } from 'interfaces/player';
import { ROLE_OPTIONS } from 'mock';
import { deleteClanMemberThunk } from 'store/slices/clan/actions';
import { isRoot, rolesValuesMap } from 'types/player';

import { DeleteModal } from 'components/DeleteModal';
import { Button, Select } from 'components/ui';

import { getClanMembers } from '../../../api/clans';
import { EUserRole, roleID } from '../../../constants/profile';

interface AccessTableProps extends IClanPlayer {
  accountId: string;
  onRemoveAccess: (id: string) => void;
  onUpdateAccess: (value: IClanPlayer) => void;
  isAccessEditing: boolean;
  isNewPlayer?: boolean;
}

export const AccessTable: FC<AccessTableProps> = ({
  clanId,
  clanName,
  roleId,
  accountId,
  onRemoveAccess,
  onUpdateAccess,
  isAccessEditing,
  isNewPlayer,
}) => {
  const roleInitialValue = ROLE_OPTIONS.find(
    (option) => option.value === roleId
  );

  const [clanPlayers, setClanPlayers] = useState<IPlayer[]>([]);

  const streamerOptions = useMemo(
    () =>
      clanPlayers
        .filter(
          (member) =>
            member.roles.some((role) => role.role_name === EUserRole.USER) &&
            member.id !== accountId
        )
        .map((member) => ({
          value: member.id,
          label: member.name,
        })),
    [clanPlayers, accountId]
  );

  const streamerId = useMemo(
    () => clanPlayers.find((player) => player.id === accountId)?.streamer_id,
    [clanPlayers, accountId]
  );

  const streamerInitialValue = useMemo(
    () => streamerOptions.find((option) => option.value === streamerId),
    [streamerOptions, streamerId]
  );

  const [selectedRole, setSelectedRole] = useState<IOption>(
    roleInitialValue || {
      value: '',
      label: '',
    }
  );

  const [selectedStreamer, setSelectedStreamer] = useState<IOption>(
    streamerInitialValue || {
      value: '',
      label: '',
    }
  );

  useEffect(() => {
    if (streamerInitialValue) {
      setSelectedStreamer(streamerInitialValue);
    }
  }, [streamerInitialValue]);

  const [isOpenDeleteModal, setOpenDeleteModal] = useState(false);

  const [deferredUpdateRole, setDeferredUpdateRole] = useState(false);

  const [isShowDeleteButton, setShowDeleteButton] = useState(false);

  const containerRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useClickOutside<HTMLDivElement, void>(containerRef, () =>
    setShowDeleteButton(false)
  );

  useEffect(() => {
    const getData = async () => {
      if (clanId) {
        const members = await getClanMembers(clanId);

        if (members.data) {
          setClanPlayers(members.data);
        }
      }
    };

    getData();
  }, [clanId]);

  const handleRightClick = (e: MouseEvent) => {
    e.preventDefault();

    setShowDeleteButton(true);
  };

  const handleSelectStreamer = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedStreamer(value);

      if (!isNewPlayer) {
        if (deferredUpdateRole) {
          setDeferredUpdateRole(false);

          updateAccountRole({
            account_id: accountId,
            clan_id: String(clanId),
            role_id: selectedRole.value,
            streamer_id: value.value,
          });
        } else {
          updateAccountStreamerId({
            account_id: accountId,
            clan_id: String(clanId),
            streamer_id: value.value,
          });
        }
      }
    }
  };

  const handleSelectRole = (value: IOption | IOption[]) => {
    if (!Array.isArray(value)) {
      setSelectedRole(value);

      if (!isNewPlayer) {
        if (
          value.value !== roleID[EUserRole.NAVIGATOR] ||
          (value.value === roleID[EUserRole.NAVIGATOR] &&
            selectedStreamer.value.length)
        ) {
          setDeferredUpdateRole(false);

          updateAccountRole({
            account_id: accountId,
            clan_id: String(clanId),
            role_id: value.value,
            streamer_id: selectedStreamer.value,
          });
        } else {
          setDeferredUpdateRole(true);
        }
      }

      onUpdateAccess({
        clanId: String(clanId),
        clanName,
        roleId: value.value,
        roleName: value.label,
      });
    }
  };

  const handleOpenModal = (event: MouseEvent) => {
    event.stopPropagation();

    setOpenDeleteModal(true);
  };

  const handleCloseModal = () => {
    setOpenDeleteModal(false);
  };

  const handleRemoveAccess = () => {
    if (!isNewPlayer) {
      dispatch(
        deleteClanMemberThunk({
          clanId: String(clanId),
          memberId: accountId,
          isAddedFromAccount: true,
        })
      );
    }

    onRemoveAccess(String(clanId));

    handleCloseModal();
  };

  return (
    <>
      <div
        ref={containerRef}
        className="border border-solid rounded-[10px] border-dark_product"
        onContextMenu={handleRightClick}
      >
        <div className="relative flex flex-col px-[16px] py-[10px] h-[59px]">
          <span className="tpg-c2 text-tpg_light">департамент</span>
          <span>{clanName}</span>
          {isAccessEditing && isShowDeleteButton && !isRoot(accountId) && (
            <Button
              className="absolute left-[140px] top-[15px] rounded-[10px] border border-solid border-tpg_light bg-ultrablack px-[16px] py-[6px] text-tpg_base hover:bg-dark_product"
              title="Удалить"
              onClick={handleOpenModal}
            />
          )}
        </div>
        {isAccessEditing ? (
          <>
            <Select
              value={selectedRole}
              options={ROLE_OPTIONS}
              onSelect={handleSelectRole}
              placeholder="Роль"
              isOptionEmpty={false}
              className={cn(
                'pl-[16px] border-t border-solid border-t-dark_product',
                {
                  'rounded-b-[10px]':
                    selectedRole.value !== rolesValuesMap[EUserRole.NAVIGATOR],
                  'border-b border-solid border-b-dark_product':
                    selectedRole.value === rolesValuesMap[EUserRole.NAVIGATOR],
                }
              )}
            />
            {selectedRole.value === rolesValuesMap[EUserRole.NAVIGATOR] && (
              <Select
                value={selectedStreamer || []}
                options={streamerOptions}
                onSelect={handleSelectStreamer}
                placeholder="Источник"
                isOptionEmpty={false}
                className="pl-[16px] rounded-b-[10px] rounded-t-[10px]"
              />
            )}
          </>
        ) : (
          <div className="h-[61px] flex flex-col px-[16px] py-[10px] border-t border-solid border-t-dark_product">
            <span className="tpg-c2 text-tpg_light">Роль</span>
            <span className="tpg-c1 pointer-events-none">
              {selectedRole.label || 'Не выбрана'}
            </span>
          </div>
        )}
      </div>
      {isOpenDeleteModal && (
        <DeleteModal
          title="Вы уверены, что хотите удалить доступ?"
          onClose={handleCloseModal}
          onRemove={handleRemoveAccess}
        />
      )}
    </>
  );
};
