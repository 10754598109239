import React, { FC, useEffect, useState } from 'react';
import cn from 'classnames';
import { format } from 'date-fns';
import { ReactComponent as TrashIcon } from 'images/newIcons/trash.svg';

import { deactivateSession, getAccountSessions } from '../../api/sessions';
import { useBreakPoint } from '../../hooks';
import { ISession } from '../../interfaces/sessions';
import { getWordDayForm } from '../../utils';

interface AccessListProps {
  accountId: string;
  isNewPlayer?: boolean;
}

export const SessionList: FC<AccessListProps> = ({ accountId }) => {
  const [sessions, setSessions] = useState<ISession[]>([]);

  const wordForm = getWordDayForm(sessions?.length, [
    'сессия',
    'сессии',
    'сессий',
  ]);

  const breakPoint = useBreakPoint();

  const handleSessionTerminate = async (session: ISession) => {
    await deactivateSession(session.id);
    setSessions((sessions) => sessions.filter((s) => s.id !== session.id));
  };

  useEffect(() => {
    const fetchData = async () => {
      const { data } = await getAccountSessions(accountId);

      data && setSessions(data);
    };

    fetchData();
  }, []);

  if (!sessions.length) {
    return <div className="p-4">Отсутствуют активные сессии</div>;
  }
  const formatDate = (date: Date) => format(date, 'dd.MM.yyyy HH:mm');

  return (
    <div
      className={cn('w-6/12 min-h-full overflow-auto p-4', {
        'w-full': breakPoint === 'mobile' || breakPoint === 'tablet',
      })}
    >
      <div className="flex flex-row justify-between bg-ultrablack pb-4 sticky top-0 z-[2]">
        <span>{`${sessions?.length} ${wordForm}`}</span>
      </div>
      <div className="[&>div]last:border-b-none">
        <div className="flex flex-col border border-solid border-dark_product rounded-[10px]">
          {sessions.map((session, i) => (
            <div
              key={`session-${session.id}`}
              className="flex flex-row items-center border-b border-solid border-b-dark_product"
            >
              <div className="p-[12px] min-w-[195px] tpg-c1">
                <span>Версия приложения: {session.app_version}</span> <br />
                <span>IP: {session.ip}</span> <br />
                {session.geo_ip && (
                  <span>
                    Расположение: {session.geo_ip.country} {session.geo_ip.city}{' '}
                    {session.geo_ip.longitude} {session.geo_ip.latitude} <br />
                  </span>
                )}
                <span>Устройство: {session.user_agent}</span>
                <br />
                <span>Последнее действие: {formatDate(session.last_seen)}</span>
                <br />
                <span>Авторизован: {formatDate(session.login_at)}</span>
                <br />
                <span>Сессия истекает: {formatDate(session.expires_at)}</span>
                <br />
              </div>
              <TrashIcon
                onClick={() => handleSessionTerminate(session)}
                className="[&>path]:fill-red w-[16px] cursor-pointer mr-2 h-[16px] [&>path]:transition"
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
