import { FC } from 'react';
import { ReactComponent as MarkerIcon } from 'images/newIcons/marker.svg';

import eventDispatcher from '../../../utils/events/eventDispatcher';
import { EventTypes } from '../../../utils/events/events';

export const NavigatorScreenControlPanel: FC = () => {
  const handleUpdateLocation = () => {
    eventDispatcher.dispatch({
      name: EventTypes.NAVIGATOR_MAP_LOCATION_UPDATED,
    });
  };

  return (
    <div
      className="flex flex-row gap-3 items-center cursor-pointer"
      onClick={handleUpdateLocation}
    >
      <span className="tpg-b1 text-center hover:text-bright_product transition">
        Дрон здесь
      </span>
      <MarkerIcon />
    </div>
  );
};
