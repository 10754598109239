import { useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks';
import { recordsSelector } from 'store/slices/records';
import { getRecordsByClanIdThunk } from 'store/slices/records/actions';

import { RecordsList } from 'components/RecordsList';

export const DepartmentRecordsScreen = () => {
  const { records } = useAppSelector(recordsSelector);

  const { state } = useLocation();

  const { id } = useParams();

  const dispatch = useAppDispatch();

  const clanId = state?.clanId ?? id;

  useEffect(() => {
    if (clanId) {
      const getRecords = () => {
        dispatch(getRecordsByClanIdThunk({ clanId }));
      };

      getRecords();
    }
  }, [clanId]);

  const recordsById = records.get(clanId) || [];

  return (
    <div className="flex flex-col gap-[16px] w-[calc(100%+48px)] h-full m-[-24px] p-[8px]">
      {clanId ? (
        <RecordsList records={recordsById} isFilterByClan={false} />
      ) : (
        <span>Нет данных о департаменте</span>
      )}
    </div>
  );
};
